<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterGudang
                    style="width:100%;"
                    v-model:distributor="state.params.distributor"
                    v-model:value="state.params.gudang"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterToko
                    style="width:100%;"
                    v-model:value="state.params.toko"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12">
            </div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip
                        v-if="hasRoles([ROLE_ADMIN_BK, ROLE_DISTRIBUTOR]) && state.isCreate"
                        title="Tambahkan">
                        <AButton
                            type="primary"
                            @click="btnForm">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip title="Download excel">
                        <DownloadExcel
                            :url="state.endpoint.current"
                            :params="state.params"
                            namefile="Detail-Target-Per-Toko-By-Distributor"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip title="Edit">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnForm(record)"
                                :disabled="_.includes([2, 3, 5], record.status_id)">
                                <i class="fe fe-edit" />
                            </AButton>
                        </ATooltip>
                        <ATooltip title="Hapus">
                            <AButton
                                class="button"
                                size="small"
                                :loading="record.isDelete"
                                @click="btnDelete(record)">
                                <i class="fe fe-trash" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>

        <!-- form -->
        <Form
            v-if="visibleFormModal"
            v-model:visible="visibleFormModal"
            v-model:item="visibleFormItemModal"
            :shipto_id="state.shipto_id"
            :vendor_id="state.vendor_id"
            :roles="[ROLE_DISTRIBUTOR]"
            only-disable
            @success="fetchDataList"/>

    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterGudang from '@/components/filter/FilterGudang'
import FilterToko from '@/components/filter/FilterToko'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import Form from './Form'
import _ from 'lodash'

import {
    hasRoles,
    ROLE_ADMIN_BK,
    ROLE_SPC,
    ROLE_DISTRIBUTOR,
    ROLE_ASM,
    ROLE_SSM,
} from '@/helpers'

export default defineComponent({
    components: {
        FilterGudang,
        FilterToko,
        DownloadExcel,
        Form,
    },
    setup() {
        const errorMessage = ref()
        const route = useRoute()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Periode',
                    dataIndex: 'periode',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'kode_distributor',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'nama_distributor',
                },
                {
                    title: 'Kabupaten/Kota',
                    dataIndex: 'kabupaten',
                },
                {
                    title: 'Kode Gudang',
                    dataIndex: 'kode_gudang',
                },
                {
                    title: 'Nama Gudang',
                    dataIndex: 'nama_gudang',
                },
                {
                    title: 'Kode Toko',
                    dataIndex: 'kode_customer',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'nama_customer',
                },
                {
                    title: 'Market Sale per Toko',
                    dataIndex: 'market_sale_target',
                },
                {
                    title: 'Demand Toko Total (Semua Brand)',
                    dataIndex: 'demand_all_brand',
                },
                {
                    title: 'MS SIG per Toko',
                    dataIndex: 'market_sale_actual',
                },
                {
                    title: 'GAP MS Toko vs MS Target',
                    dataIndex: 'market_sale_gap',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: {
                current: `/api/snop/target-penjualan-distributor/${route.params.id}/target-distributor`,
                detail: `/api/snop/target-penjualan-distributor/${route.params.id}/detail-target-id`,
                isCreate: `api/snop/target-penjualan-distributor/${route.params.id}/add-target-dist-btn`,
            },
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isCreate: true,
            params: {
                q: '',
                gudang: [],
                toko: [],
                page: 1,
                "per-page": 10,
            },
            detail: null,
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            return _.pickBy(params)
        }

        // Function to get unique brands from data
        const getUniqueBrands = (data, key) => {
            const brands = _.flatMap(data, item => item[key])
            return _.uniqBy(brands, 'brand_id')
        }

        // Generate columns dynamically
        const generateDynamicColumns = (data) => {
            if (_.some(state.columns, { dataIndex: 'demand_distributor' })) {
                _.remove(state.columns, { dataIndex: 'demand_distributor' });
            }

            let columns = []
            const distributorBrands = getUniqueBrands(data, 'brands')
            columns.push({
                title: `Target Per Brand By Distributor`,
                dataIndex: 'demand_distributor',
                children: [
                    ...distributorBrands.map(brand => ({
                        title: brand.brand_name,
                        dataIndex: `demand_distributor_${brand.brand_id}`,
                    })),
                    {
                        title: 'Total',
                        dataIndex: 'demand_distributor_total',
                    },
                ],
            })

            return columns
        }

        const transformData = (data) => {
            return data.map(item => {
                // Process brands
                let demand_distributor_total = 0
                item.brands.forEach(brand => {
                    item[`demand_distributor_${brand.brand_id}`] = brand.demand_value
                    demand_distributor_total += brand.demand_value
                })
                item['demand_distributor_total'] = demand_distributor_total

                return item
            })
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    items.forEach((item, index) => {
                        item.isDelete = false
                    })

                    state.columns.splice(9, 0, ...generateDynamicColumns(items))

                    state.data = transformData(items)
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // fetch detail
        const fetchDetail = () => {
            apiClient
                .get(state.endpoint.detail)
                .then(({ data }) => {
                    state.detail = data
                })
        }

        // fetch is create
        const fetchIsCreate = () => {
            apiClient
                .get(state.endpoint.isCreate)
                .then(({ data }) => {
                    state.isCreate = data
                })
        }

        const btnDelete = record => {
            Modal.confirm({
                title: 'Konfirmasi Hapus',
                content: 'Apakah Anda ingin hapus item tersebut?',
                onOk() {
                    record.isDelete = true

                    apiClient
                        .delete(`api/snop/target-penjualan-distributor/${record.id}/target-toko`)
                        .then((response) => {
                            fetchDataList()
                            message.success('Berhasil dihapus')
                        })
                        .catch(e => message.error('Gagal menghapus!'))
                        .finally(() => {
                            record.isDelete = false
                        })
                },
                onCancel() { },
            })
        }

        // handle form
        const visibleFormModal = ref(false)
        const visibleFormItemModal = ref(null)

        const btnForm = (item = null, readOnly = false) => {
            visibleFormModal.value = true

            if (state.detail) {
                Object.assign(item, {
                    shipto_id: state.detail.shipto_id,
                    shipto_code: state.detail.shipto_code,
                    shipto_name: state.detail.shipto_name,
                    vendor_id: state.detail.vendor_id,
                    kode_distributor: state.detail.vendor_code,
                    nama_distributor: state.detail.vendor_name,
                })

            }

            visibleFormItemModal.value = {
                ...item,
                readOnly,
            }
        }

        // handle vue
        onMounted(() => {
            fetchDetail()
            fetchIsCreate()
            fetchDataList()

            // show column action only this roles
            if (!hasRoles([ROLE_DISTRIBUTOR])) {
                state.columns = state.columns.filter(item => {
                    if (!_.includes(['action'], item.dataIndex)) {
                        return item
                    }
                })
            }
        })

        return {
            _,
            state,
            errorMessage,
            handleTableChange,
            fetchDataList,
            btnForm,
            visibleFormModal,
            visibleFormItemModal,
            btnDelete,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
            ROLE_SPC,
            ROLE_DISTRIBUTOR,
            ROLE_ASM,
            ROLE_SSM,
        }
    },
})
</script>
